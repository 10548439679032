const API_URL = process.env.REACT_APP_API_URL || '';

const authProvider = {
    login: params => {
        const { username, password } = params;
        const request = new Request(`${API_URL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token, refresh_token }) => {
                localStorage.setItem('token', access_token);
                localStorage.setItem('refresh', refresh_token);
            });
    },
    logout: params => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkError: params => {
        const status = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');

            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: params => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: params => Promise.resolve(),
    getIdentity: () => Promise.resolve(),
}

export default authProvider;