import * as React from "react";
import finnishMessages from 'ra-language-finnish';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { fetchUtils, Admin, Resource, Login } from 'react-admin';
import createDataProvider from './api/dataProvider';
import authProvider from './api/authProvider';
import { MemberList, MemberEdit } from './components/members';


const i18nProvider = polyglotI18nProvider(() => finnishMessages, 'fi');
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#FFFFFF',
      dark: '#ba000d',
      contrastText: '#808080',
    },
  },
  overrides: {
    MuiTableRow: {
      head: {
        backgroundColor: 'lightgray',
        "& > th ": {
          borderBottom: "1px solid black",
          color: 'rgba(0, 0, 0, 0.54)',
          paddingTop: "21px",
          paddingBottom: "21px"
        }
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#A04191'
      }
    },
    MuiToolbar: {
      root: {
        "& .filter-field .MuiInputBase-root": {
          width: "500px"
        }
      }
    }
  }
});

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
}

const dataProvider = createDataProvider(httpClient);

const MyLoginPage = () => {
  const loginStyle = makeStyles((theme) => ({
    center: {
      position: "relative",
      "&>.MuiPaper-root": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        marginTop: "0px"
      }
    }
  }))();
  return (
    <Login className={loginStyle.center} backgroundImage="/login-background.jpg" theme={theme} />
  )
};

const App = () => (
  <Admin title="Liittohaku" loginPage={MyLoginPage} i18nProvider={i18nProvider} authProvider={authProvider} dataProvider={dataProvider} theme={theme}>
    <Resource name="members" options={{label: "Jäsenlista"}} list={MemberList} edit={MemberEdit} />
  </Admin>
);

export default App;
