const API_URL = process.env.REACT_APP_API_URL;

const cache = new Map();

const createDataProvider = (client) => ({
  getList: async (resource, params) => {
    const query = {
      sort: JSON.stringify(params.sort),
      pagination: JSON.stringify(params.pagination),
      filter: JSON.stringify(params.filter),
    };
    const res = await cached(`${API_URL}/${resource}/?${new URLSearchParams(query)}`,client);
    const data = res.json;
    data.total = parseInt(data.total)
    return data;
  },
  getOne: async (resource, params) => {
    const res = await cached(`${API_URL}/${resource}/${params.id}/`, client);
    const data = res.json;
    return data;
  },
  resend: async (resource, params) => {
    try {
      const res = await client(`${API_URL}/${resource}/${params.id}/resend`, {
        method: 'POST'
      });
      return res.json;
    } catch(e) {
      console.log(e);
    }
  }
})

const cached = async (url, client) => {
  const res = cache.get(url);
  if (res) {
    return res;
  } else {
    const newRes = await client(url);
    cache.set(url, newRes);
    return newRes;
  }
};

export default createDataProvider;