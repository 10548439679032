import React, { useState } from "react";
import { List, Datagrid, TextField, EditButton, Edit,
    TextInput, Filter, DatagridBody, useDataProvider, SelectInput} from 'react-admin';

import { InputLabel, Typography, Grid, Card, CardContent, Paper, Button, Box, TableCell, TableRow, Fade, Chip } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faChevronLeft, faDollarSign, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    editRoot: {
      flexGrow: 1,
      padding: "30px"
    },
    edit: {
        height: "100%",
    },
    main: {
        height: "100%",
    },
    root: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
    sendButtonContainer: {
        float: "right"
    },
    sendButton: {
        width: "260px",
    },
    sendButtonIcon: {
        marginRight: "12px",
        verticalAlign: "middle"
    },
    footer: {
        width: "100%",
    },
    footerButtonContainer: {
        padding: "30px",
    },
    subscriptionsPaper: {
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none"
    },
    subscriptionCard: {
        marginBottom: "16px",
        textTransform: "capitalize"
    },
    ellipseContainer: {
        backgroundColor: "#BDBDBD",
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        textAlign: "center",
        display: "table",
    },
    ellipseDollar: {
        verticalAlign: "middle",
        display: "table-cell",
        color: "#FFFFFF"
    },
    failedStatus: {
        color: "#F44335",
    },
    noScrollBar: {
        "&>textarea": {
            "&::-webkit-scrollbar": {
                display: "none",
            }
        }
    },
    chip: {
        marginBottom: theme.spacing(1),
    },
  }));

const QuickFilter = ({ label }) => {
    const classes = useStyles();
    return <Chip className={classes.chip} label={label} />;
};

const choices=[{id:'OK', name:'OK'}, {id:'LOADING', name:'LOADING'},{id:'PENDING', name:'PENDING'}, {id:'QUEUEING', name:'QUEUEING'},  {id:'PROCESSING', name:'PROCESSING'}, {id:'FAILED', name:'FAILED'}]

const MemberFilter = (props) => (
    <Filter {...props}>   
        <TextInput label="Search" source="q" alwaysOn/>     
        <SelectInput label="Status" source="status" choices={choices}/>
        <QuickFilter source="email" label="Sähköposti" defaultValue={true}/>
        <QuickFilter source="first_name" label="Etunimi" defaultValue={true}/>
        <QuickFilter source="last_name" label="Sukunimi" defaultValue={true}/>
        <QuickFilter source="member_number" label="Jäsennumero" defaultValue={true}/>
        <QuickFilter source="street1" label="Osoite" defaultValue={true}/>    
    </Filter>
);

const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => {
    const classes = makeStyles((theme) => ({
        FAILED: {
            borderLeft: "4px solid #F44335"
        },
        OK: {
            borderLeft: "4px solid #4BAF4F"
        },
        QUEUING: {
            borderLeft: "4px solid #FF9800"
        },
        LOADING: {
            borderLeft: "4px solid #FF9800"
        },
        PENDING: {
            borderLeft: "4px solid #FF9800"
        },
        PROCESSING: {
            borderLeft: "4px solid #FF9800"
        }
    }))();
    return (
        <TableRow classes={{root: classes[record.status]}} key={id}>
            {/* data columns based on children */}
            {React.Children.map(children, field => (
                <TableCell key={`${id}-${field.props.source}`}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </TableCell>
            ))}
        </TableRow>
)};

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

export const MemberList = props => {
    return (
        <List perPage={25} filters={<MemberFilter />} {...props} title="LIITTOHAKU" bulkActionButtons={false}>            
            <MyDatagrid rowClick="edit">
                <TextField source="union_name" label="Liitto" />
                <TextField source="first_name" label="Etunimi" />
                <TextField source="last_name" label="Sukunimi" />
                <TextField source="member_number" label="Jäsennumero" />
                <TextField source="email" label="Sähköposti" />
                <TextField source="status" label="Status"/>
                <EditButton label="Avaa" />
            </MyDatagrid>
        </List>
    );
}
export const MemberEdit = props => {
    const classes = useStyles();
    console.log(props);
    return (
        <Edit title={"LIITTOHAKU"} {...props} classes={{root: classes.edit, main: classes.main, card: classes.root}}>
            <MemberForm />
        </Edit>
    )
};


export const MemberForm = ({ basePath, record, save, saving, version }) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [resendStatus, setResendStatus] = useState(0);
    const submit = async () => {
        setResendStatus(null);
        try {
            await dataProvider.resend('members', {id: record.id});
            setResendStatus("OK");
        } catch(e) {
            setResendStatus("FAILED");
        }
    };

    const statusColors = makeStyles((theme) => ({
        FAILED: {
            color: "#F44335"
        },
        OK: {
            color: "#4BAF4F"
        },
        QUEUING: {
            color: "#FF9800"
        },
        LOADING: {
            color: "#FF9800"
        },
        PENDING: {
            color: "#FF9800"
        },
        PROCESSING: {
            color: "#FF9800"
        }
    }))();
    const latestUpdate = DateTime.fromISO(record.latest_update).setLocale("fi");

    return (
        <Grid container justify="flex-end" spacing={1}>
            <Grid item xs={8}>
                <Paper>
                    <Form
                        initialValues={record}
                        onSubmit={submit}
                        mutators={{ ...arrayMutators }} // necessary for ArrayInput
                        subscription={defaultSubscription} // don't redraw entire form each time one field changes
                        key={version} // support for refresh button
                        keepDirtyOnReinitialize
                        render={formProps => (
                            <form>
                                <div className={classes.editRoot}>
                                    <Grid container justify="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">{record.first_name + " " + record.last_name}</Typography>
                                            <Typography variant="caption" display="block">
                                                <Box color="#808080">{"Viimeisin muutos: " + latestUpdate.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</Box>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput source="union_name" resource="members" fullWidth variant={"standard"} label="Liitto" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput source="member_number" resource="members" fullWidth variant={"standard"} label="Jäsennumero" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput source="first_name" resource="members" fullWidth variant={"standard"} label="Etunimi" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput source="last_name" resource="members" fullWidth variant={"standard"} label="Sukunimi" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput source="email" resource="members" fullWidth variant={"standard"} label="Sähköposti" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput InputProps={{className: statusColors[record.status]}} source="status" resource="members" fullWidth variant={"standard"} label="Status" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>Virheilmoitus</InputLabel>
                                            <TextInput multiline rows={5} InputProps={{className: classes.noScrollBar}} source="error" resource="members" label="" fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel>XML</InputLabel>
                                            <TextInput multiline InputProps={{className: classes.noScrollBar}} rows={10} source="original_xml" resource="members" label="" fullWidth />
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    />
                    <div className={classes.footer}>
                        <div className={classes.footerButtonContainer}>
                            <Button href="/" color="primary">
                                <span><FontAwesomeIcon className={classes.backButtonIcon} icon={faChevronLeft} /> TAKAISIN</span>
                            </Button>
                            <div className={classes.sendButtonContainer}>
                                {resendStatus === "OK" && <Fade in={true}><FontAwesomeIcon color="#4BAF4F" className={classes.sendButtonIcon} icon={faCheck} /></Fade>}
                                {resendStatus === "FAILED" && <Fade in={true}><FontAwesomeIcon color="#F44335" className={classes.sendButtonIcon} icon={faTimes} /></Fade>} 
                                <Button variant="contained" color="primary" className={classes.sendButton} onClick={submit}>
                                    <span><FontAwesomeIcon className={classes.sendButtonIcon} icon={faPaperPlane} /> LÄHETÄ UUDESTAAN</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper className={classes.subscriptionsPaper}>
                {record.subscriptions.map(subscription => <SubscriptionCard key={subscription.id} {...subscription} />)}
                </Paper>
            </Grid>
        </Grid>
    );
}

const SubscriptionCard = props => {
    const classes = useStyles();
    return (
        <Card className={classes.subscriptionCard}>
            <CardContent>
                <Grid container spacing={7}>
                    <Grid item xs={2}>
                        <div className={classes.ellipseContainer}>
                            <span className={classes.ellipseDollar}><FontAwesomeIcon icon={faDollarSign} /></span>
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography component="div"><Box fontWeight="bold">{`Tuote: ${props.product_code}`}</Box></Typography>
                        <Typography component="div"><Box marginTop="0.5rem" fontSize="16px">Osoite:</Box></Typography>
                        <Typography component="div">
                            <Box lineHeight="1.2" color="#808080">{props.street1 ? props.street1.toLowerCase() : null} {props.street2 ? props.street2 : null}</Box>
                            {props.postal_code && <Box lineHeight="1.2" color="#808080">{props.postal_code}</Box>}
                            {props.city && <Box lineHeight="1.2" color="#808080">{props.city.toLowerCase()}</Box>}
                            {props.country && <Box lineHeight="1.2" color="#808080">{props.country}</Box>}
                            {props.phone && <Box lineHeight="1.2" color="#808080">{props.phone}</Box>}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};